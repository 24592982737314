<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created(){
      if(this.$router.currentRoute.path!="/Home"){
        this.$router.push('/Home')
      }
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body{
  margin: 0;
}

</style>
